import React, { useRef, useEffect, useState } from 'react';

import { DandyPLYLoader } from './DandyPLYLoader';
//import { DandyPLYExporter } from './DandyPLYExporter';
import { DandyLabelLoader } from './DandyLabelLoader';
import { DandyLabelExporter } from './DandyLabelExporter';

import options from '../config.json';
import { BufferAttribute, Color } from 'three';
import { VertexFaceIndex } from './VertexFaceIndex';

import styles from './styles.module.css';
import ProgressBar from '../ProgressBar';
import useDocumentTitle from '../useDocumentTitle';
import { BackendApi } from '../Api/BackendApi';

function ImportExport({
  userInfo,
  setUserInfo,
  loadedAnnotationData,
  setLoadedAnnotationData,
  annotationData,
  setAnnotationData,
  mesh,
  geometry,
  setGeometry,
  setFaceIndex,
  bucket,
}) {
  let [plyFile, setPlyFile] = useState(null);
  const [message, setMessage] = useState('');

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [backendApi] = useState(new BackendApi());

  useDocumentTitle(plyFile ? `${plyFile.name} - Mesh Labeler` : 'Mesh Labeler');

  const triggerLogin = () => {
    if (!bucket) return;
    setMessage('Authenticating...');

    bucket
      .fetchUserInfo()
      .then((userInfo) => {
        setUserInfo(userInfo);
        if (userInfo.userEmail) {
          setMessage(`Successfully logged in`);
        } else {
          setMessage(`Not logged in`);
        }
      })
      .catch((e) => setMessage(e.message))
      .finally(() => setLoading(false));
  };

  const triggerLoginAndDownload = (filename) => {
    if (!bucket) return;

    // Load the file, call setPlyFile on it
    setLoading(true);
    setMessage('Authenticating...');

    bucket
      .fetchUserInfo()
      .then((userInfo) => {
        setUserInfo(userInfo);
        if (!userInfo.userEmail) {
          setMessage(`Not logged in`);
          return;
        }
        setMessage(`Downloading labels as ${userInfo.userEmail}...`);
        bucket
          .downloadBucketObject(filename + '.json')
          .then((data) => {
            setMessage(`Downloading mesh as ${userInfo.userEmail}...`);
            const loader = new DandyLabelLoader(
              setLoadedAnnotationData,
              setAnnotationData
            );
            loader.load(data, () => {
              bucket
                .downloadBucketObject(filename, setProgress)
                .then((url) => {
                  setMessage(
                    `Success! Mesh was annotated by ${data.annotatorInfo?.userEmail}`
                  );
                  setProgress(0);
                  setPlyFile({ url, name: filename });
                })
                .catch((e) => setMessage(e.message))
                .finally(() => setLoading(false));
            });
          })
          .catch((e) => setMessage(e.message))
          .finally(() => setLoading(false));
      })
      .catch((e) => setMessage(e.message))
      .finally(() => setLoading(false));
  };

  const triggerLoginAndDownloadUuid = (uuid) => {
    if (!bucket) return;

    // Load the file, call setPlyFile on it
    setLoading(true);
    setMessage('Authenticating...');

    bucket
      .fetchUserInfo()
      .then((userInfo) => {
        setUserInfo(userInfo);
        if (!userInfo.userEmail) {
          throw new Error(`Not logged in`);
        }
        return backendApi.makeDownloadUrlsRequest(uuid);
      })
      .then(async ({ labels_url, scan_url }) => {
        const labelsJson = await (await fetch(labels_url)).json();
        console.log(
          'Number of tooth vertex labels:',
          labelsJson.vertexMasks.Tooth.length
        );
        const labelLoader = new DandyLabelLoader(
          setLoadedAnnotationData,
          setAnnotationData
        );
        labelLoader.load(labelsJson);
        setPlyFile({ url: scan_url, name: 'PLY File' });
      })
      .catch((e) => setMessage(e.message))
      .finally(() => setLoading(false));
  };

  const location = window.location;

  const url = new URL(location.href);
  let itemUuid = undefined;
  if (url.pathname.startsWith('/label/')) {
    itemUuid = url.pathname.slice('/label/'.length);
  }

  useEffect(() => {
    if (!bucket) return;
    if (itemUuid) {
      console.log('Loading', itemUuid);
      setTimeout(() => triggerLoginAndDownloadUuid(itemUuid), 100);
    } else {
      setTimeout(() => triggerLogin(), 100);
    }
  }, [itemUuid, bucket]);

  useEffect(() => {
    if (plyFile) {
      // Disable texcoord loading, otherwise PLYLoader will convert
      // to unindexed geometry
      const loader = new DandyPLYLoader();
      loader.setPropertyNameMapping({ texcoord: 'null' });
      loader.load(plyFile.url, (geometry) => {
        console.log('loaded geometry', geometry);
        if (!geometry.hasAttribute('normal')) {
          geometry.computeVertexNormals();
        }

        const colorAttr = geometry.getAttribute('color');
        if (!geometry.hasAttribute('original_color') && colorAttr) {
          const colorAttributeCopy = colorAttr.clone();
          geometry.setAttribute('original_color', colorAttributeCopy);
        }

        options.groups.forEach((groupId) => {
          const attrName = `${groupId}_mask`;

          if (!geometry.hasAttribute(attrName)) {
            const vertexCount = geometry.attributes.position.count;
            const attrArray = new Uint8Array(vertexCount);
            const maskAttr = new BufferAttribute(attrArray, 1);
            geometry.setAttribute(attrName, maskAttr);
          } else {
            console.log(`${attrName} group loaded`);
          }
        });

        const index = new VertexFaceIndex(geometry);
        index.build();

        setFaceIndex(index);

        setGeometry(geometry);
      });
    }
  }, [plyFile, setGeometry, setFaceIndex]);

  useEffect(() => {
    if (!geometry || !loadedAnnotationData) {
      return;
    }

    const colorAttr = geometry.getAttribute('color');
    const labelColorMap = {};
    options.labels.forEach(({ group, id, color }) => {
      labelColorMap[`${group}-${id}`] = color;
    });
    options.groups.forEach((groupId) => {
      const attrName = `${groupId}_mask`;
      const maskAttr = geometry.getAttribute(attrName);
      (loadedAnnotationData.vertexMasks[groupId] || []).forEach(
        (labelId, idx) => {
          maskAttr.setX(idx, labelId);
          const labelColor = labelColorMap[`${groupId}-${labelId}`];
          if (!labelColor) return;
          const color = new Color(labelColor);
          colorAttr.setXYZ(idx, color.r, color.g, color.b);
        }
      );
    });
    colorAttr.needsUpdate = true;
  }, [loadedAnnotationData, geometry]);

  const getDownloadData = () => {
    const exporter = new DandyLabelExporter();

    // Download the file
    const data = exporter.parse(
      userInfo,
      annotationData,
      options.groups,
      mesh.current
    );

    return data;
  };

  // If a critical error happens, make the user very aware of it.
  function displayError(context, e) {
    let msg;
    if (e instanceof Error) {
      msg = JSON.stringify(e.toString());
    } else {
      msg = JSON.stringify(e, null, 1);
    }

    msg = 'An Error occurred during ' + context + ': ' + msg;
    setMessage(msg);
    alert(msg);
  }

  return (
    <>
      {plyFile && (
        <>
          <title>{plyFile.name} - Mesh Labeler</title>
          <div className={styles.filenameDisplay}>
            <span>{plyFile.name}</span>
          </div>
          <div>
            <button
              disabled={loading}
              onClick={() => {
                setLoading(true);
                setMessage('Uploading...');
                setTimeout(() => {
                  const description = 'Export to Google Bucket';
                  try {
                    const fileData = getDownloadData();
                    bucket
                      .uploadLabels(itemUuid, fileData, setProgress)
                      .then(() => {
                        return backendApi.makeMarkSubmissionRequest(itemUuid);
                      })
                      .then(() => {
                        setMessage(description + ' Successful!');
                        setProgress(0);
                      })
                      .catch((e) => displayError(description, e))
                      .finally(() => setLoading(false));
                  } catch (e) {
                    // an error occured while preparing the data to upload
                    displayError(description, e);
                  }
                }, 100);
              }}
            >
              Export to Google Bucket
            </button>
          </div>
        </>
      )}
      {message && (
        <div className={styles.bucketMessage}>
          <p>{message}</p>
        </div>
      )}
      {loading && <ProgressBar progress={progress} />}
    </>
  );
}

export default ImportExport;
