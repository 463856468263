export class BackendApi {
  constructor() {
    this.baseUrl = process.env.REACT_APP_BACKEND_API_URL;
  }

  async makeMarkSubmissionRequest(itemUuid) {
    const response = await fetch(`${this.baseUrl}/mark_submission`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({ uuid: itemUuid }),
    });
    return response;
  }

  async makeDownloadUrlsRequest(itemUuid) {
    const response = await fetch(`${this.baseUrl}/download_urls`, {
      method: 'POST',
      headers: { 'Content-Type': 'applcation/json' },
      credentials: 'include',
      body: JSON.stringify({ uuid: itemUuid }),
    });
    return response.json();
  }

  async makeLabelUploadUrlRequest(itemUuid) {
    const response = await fetch(`${this.baseUrl}/label_upload_url`, {
      method: 'POST',
      headers: { 'Content-Type': 'applcation/json' },
      credentials: 'include',
      body: JSON.stringify({ uuid: itemUuid }),
    });
    return response.json();
  }
}
