import { BackendApi } from '../Api/BackendApi';

function makeResponseSummary(status, url, xhr) {
  let response = {};
  let json_parsed = false;
  const response_text_type =
    xhr.responseType == 'json' ||
    xhr.responseType == '' ||
    xhr.responseType == 'text';

  if (response_text_type && xhr.responseText) {
    try {
      response = JSON.parse(xhr.responseText);
      json_parsed = true;
    } catch (e) {}
  }
  response['status'] = status;
  response['statusText'] = xhr.statusText;
  response['url'] = url;
  response['responseType'] = xhr.responseType;
  if (response_text_type && !json_parsed && xhr.responseText) {
    response['responseText'] = xhr.responseText;
  }

  return response;
}

class CloudBucket {
  constructor() {
    this.backendApiUrl = process.env.REACT_APP_BACKEND_API_URL;
    this.backendApi = new BackendApi();
  }

  async fetchUserInfo() {
    const result = await this.makeFetchUserInfoRequest();
    return JSON.parse(result);
  }

  makeFetchUserInfoRequest() {
    let backendApiUrl = this.backendApiUrl;
    return new Promise(function (resolve, reject) {
      let xhr = new XMLHttpRequest();
      xhr.withCredentials = true;
      const u = `${backendApiUrl}/user_info`;
      console.log('Fetching user info from', u);
      xhr.open('GET', u);
      xhr.onload = function () {
        if (this.status === 200) {
          resolve(xhr);
        } else {
          reject(makeResponseSummary(this.status, u, xhr));
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText,
          url: u,
        });
      };
      xhr.send();
    }).then(function (xhr) {
      return xhr.response;
    });
  }

  async downloadBucketObject(fileName, progressCallback = () => {}) {
    /*
    let result = await this.makeDownloadRequest(fileName, progressCallback);
    */
    const downloadData = await this.makeDownloadUrlRequest(fileName);
    const { responseType, signedUrl } = JSON.parse(downloadData);
    const result = await this.makeDownloadViaUrlRequest(
      responseType,
      signedUrl,
      progressCallback
    );
    return result;
  }

  makeDownloadUrlRequest(fileName) {
    let backendApiUrl = this.backendApiUrl;
    return new Promise(function (resolve, reject) {
      let xhr = new XMLHttpRequest();
      xhr.withCredentials = true;
      const u = `${backendApiUrl}/download_url/${encodeURIComponent(fileName)}`;
      xhr.open('GET', u);
      xhr.onload = function () {
        if (this.status === 200) {
          resolve(xhr);
        } else {
          reject(makeResponseSummary(this.status, u, xhr));
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText,
          url: u,
        });
      };
      xhr.send();
    }).then(function (xhr) {
      return xhr.response;
    });
  }

  makeDownloadViaUrlRequest(responseType, signedUrl, progressCallback) {
    return new Promise(function (resolve, reject) {
      let xhr = new XMLHttpRequest();
      xhr.open('GET', signedUrl);
      xhr.onprogress = function (event) {
        progressCallback(event.loaded / event.total);
      };
      xhr.onload = function () {
        if (this.status === 200) {
          resolve(xhr);
        } else {
          reject(makeResponseSummary(this.status, signedUrl, xhr));
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText,
          url: signedUrl,
        });
      };
      xhr.responseType = responseType;
      xhr.send();
    }).then(function (xhr) {
      let result =
        responseType === 'blob'
          ? window.URL.createObjectURL(xhr.response)
          : xhr.response;
      return result;
    });
  }

  makeDownloadRequest(fileName, progressCallback) {
    let backendApiUrl = this.backendApiUrl;
    return new Promise(function (resolve, reject) {
      let xhr = new XMLHttpRequest();
      xhr.withCredentials = true;
      const u = `${backendApiUrl}/download/${encodeURIComponent(fileName)}`;
      xhr.open('GET', u);
      xhr.onprogress = function (event) {
        progressCallback(event.loaded / event.total);
      };
      xhr.onload = function () {
        if (this.status === 200) {
          resolve(xhr);
        } else {
          reject(makeResponseSummary(this.status, u, xhr));
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText,
          url: u,
        });
      };
      xhr.responseType = 'blob';
      xhr.send();
    }).then(function (xhr) {
      let result = window.URL.createObjectURL(xhr.response);
      return result;
    });
  }

  async uploadLabels(itemUuid, labelJson, progressCallback = () => {}) {
    /*
    let upload_request = await this.makeUploadRequest(object, objName, progressCallback);
    */
    const { label_upload_url } =
      await this.backendApi.makeLabelUploadUrlRequest(itemUuid);
    console.log('uploading via URL');
    const upload_request = await this.makeUploadViaUrlRequest(
      labelJson,
      'application/json',
      label_upload_url,
      progressCallback
    );
    return upload_request;
  }

  makeUploadUrlRequest(fileName) {
    let backendApiUrl = this.backendApiUrl;
    return new Promise(function (resolve, reject) {
      let xhr = new XMLHttpRequest();
      xhr.withCredentials = true;
      const u = `${backendApiUrl}/upload_url/${encodeURIComponent(fileName)}`;
      xhr.open('GET', u);
      xhr.onload = function () {
        if (this.status === 200) {
          resolve(xhr);
        } else {
          reject(makeResponseSummary(this.status, u, xhr));
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText,
          url: u,
        });
      };
      xhr.send();
    }).then(function (xhr) {
      return xhr.response;
    });
  }

  makeUploadViaUrlRequest(object, contentType, signedUrl, progressCallback) {
    return new Promise(function (resolve, reject) {
      let xhr = new XMLHttpRequest();
      xhr.upload.onprogress = function (event) {
        progressCallback(event.loaded / event.total);
      };
      xhr.onload = function () {
        if (this.status === 200) {
          resolve();
        } else {
          reject(makeResponseSummary(this.status, signedUrl, xhr));
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText,
          url: signedUrl,
        });
      };
      xhr.open('PUT', signedUrl);
      xhr.setRequestHeader('Content-Type', contentType);
      xhr.send(object);
    });
  }

  makeUploadRequest(object, objName, progressCallback) {
    let backendApiUrl = this.backendApiUrl;
    return new Promise(function (resolve, reject) {
      const u = `${backendApiUrl}/upload/${encodeURIComponent(objName)}`;
      let xhr = new XMLHttpRequest();
      xhr.withCredentials = true;
      xhr.upload.onprogress = function (event) {
        progressCallback(event.loaded / event.total);
      };
      xhr.onload = function () {
        if (this.status === 200) {
          resolve();
        } else {
          reject(makeResponseSummary(this.status, u, xhr));
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText,
          url: u,
        });
      };
      xhr.open('POST', u);
      xhr.send(object);
    });
  }
}

export default CloudBucket;
